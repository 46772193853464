import Config from './Config';
import React from 'react';
import { faQuestionCircle, faChartPie, faAudioDescription, faSearch, faComments, faPiggyBank
	,faChartLine, faTv, faBroadcastTower, faNewspaper, faCheckCircle, faChartBar, faTasks, faEuroSign, faCoins, faFlagCheckered
	,faCar, faCheckSquare
} from '@fortawesome/free-solid-svg-icons';
//import { faSearchengin } from '@fortawesome/free-brands-svg-icons/faSearchengin';
//import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const mountPointNormalized = Config.MOUNT_POINT.replace(/\/$/, '');

const DASH_TYPE_TO_ICON = {
	'overview': faChartPie
	,'overview-new': faChartPie
	,'overview-offline': faChartPie
	,'display-video': faAudioDescription
	,'display-video-pop': faAudioDescription
	,'display-video-overview': faAudioDescription
	,'display-video-awareness': faAudioDescription
	,'display-video-consideration': faAudioDescription
	,'display-video-conversion': faAudioDescription
	,'ecommerce': faCar
	,'ecommerce-new': faPiggyBank
	,'search': faSearch
	,'search-sa360-new': faSearch
	,'search-gads-new': faSearch
	,'search-seo': faSearch
	,'search-seo-new': faSearch
	,'search-loreal': faSearch
	,'social': faComments
	,'social-new': faComments
	,'analytics': faChartLine
	,'analytics-new': faChartLine
	,'cos-roas': faPiggyBank
	,'tv': faTv
	,'tv-new': faTv
	,'drtv': faTv
	,'radio': faBroadcastTower
	,'radio-new': faBroadcastTower
	,'press': faNewspaper
	,'media-quality': faCheckCircle
	,'investments': faEuroSign
	,'investments-new': faEuroSign
	,'investments-admin': faCoins
	,'competition': faFlagCheckered
	,'others': faChartBar
	,'others-new': faChartBar
	,'naming-warnings': faTasks
	,'naming-warnings-new': faTasks
	,'tutorial-new': faCheckSquare
};

function getAbsoluteURL(path) {
	return `${mountPointNormalized}${path}`;
}

function stripBaseURL(path) {
	return path.replace(new RegExp(`^${mountPointNormalized}`), '');
}

function getDashboardURL(clientId, dashId) {
	return getAbsoluteURL(`/dashboards/${clientId}/${dashId || ''}`);
}

function buildClientTaxonomyTree(item) {
	let trail = [];
	if (item.parent) {
		trail = buildClientTaxonomyTree(item.parent);
	}
	return trail.concat(item.name);
}

function buildClientTaxonomyObjectsTree(item) {
	let trail = [];
	if (item.parent) {
		trail = buildClientTaxonomyObjectsTree(item.parent);
	}
	return trail.concat(item);
}

function orderDashboardsAndSetFavourite(dashboards, favouriteIds) {
	return dashboards.map(
		(item) => ({ ...item, favourite: favouriteIds.indexOf(item.id) != -1 })
	).sort( (a, b) => {
		if (a.embedInfo && a.embedInfo.type == 'overview') {
			return -1;
		}
		else if (b.embedInfo && b.embedInfo.type == 'overview') {
			return 1;
		}
		if (a.favourite && !b.favourite){
			return -1;
		}
		else if (b.favourite && !a.favourite){
			return 1;
		}
		return a.weight - b.weight;
	});
}

function getDashTypeName(dash) {
	const type = dash.embedInfo && dash.embedInfo.type || 'unknown';
	return Config.EMBED_INFO_BY_TYPE[type] && Config.EMBED_INFO_BY_TYPE[type].label || dash.name;
}

function getFontAwesomeDashIcon(dash) {
	const type = dash.embedInfo ? dash.embedInfo.type : 'unknown';
	let icon = DASH_TYPE_TO_ICON[type] || faQuestionCircle;

	return icon;
}

const TREE_SEP = '»';
const buildTxtParentTree = (item) => {
        let trail = [];
        if (item.parent && item.level != 'agency') {
                trail = buildTxtParentTree(item.parent);
        }
        return trail.concat(item.name);
};

function getUploadAndExportStatuses(grants) {
	let canUpload = false, canExport = false;
	grants.forEach( (grant) => {
		if (!canExport) {
			let exports = (grant.resourceTypes||[]).filter( item => item.match(/-export$/i) );
			if (exports.length > 0){
				canExport = true;
			}
		}
		if (!canUpload) {
			let uploads = (grant.resourceTypes||[]).filter( item => !item.match(/-export$/i) );
			if (uploads.length > 0){
				canUpload = true;
			}
		}
	});
	return { canUpload, canExport };
}

const validatePassword = (rule, value, callback) => {
	const DIGIT_RE = /\d/g, UPPERCASE_RE = /[A-Z]/g, LOWERCASE_RE = /[a-z]/g, SPECIAL_CHARS_RE = /[^A-Za-z0-9]/;
	if (value && 
		( value.length < 8 || !value.match(DIGIT_RE) || !value.match(SPECIAL_CHARS_RE)
		  || !value.match(UPPERCASE_RE) || !value.match(LOWERCASE_RE) )
	) {
		return callback('Password length should be at least 8 characters and should contain a number, an uppercase and a lowercase letter and a special character');
	}
	return callback();
};

export { getAbsoluteURL, stripBaseURL
	,buildClientTaxonomyTree, buildClientTaxonomyObjectsTree
	,orderDashboardsAndSetFavourite
	,getDashboardURL
	,getDashTypeName, getFontAwesomeDashIcon
	,TREE_SEP, buildTxtParentTree
	,getUploadAndExportStatuses
	,validatePassword
};
