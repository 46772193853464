const MOUNT_POINT = process.env.REACT_APP_MOUNT_POINT;
const API_ROOT = process.env.REACT_APP_API_ROOT;
const FRONTEND_BASE = process.env.REACT_APP_FRONTEND_BASE;

const conf = {
        MOUNT_POINT
	,FRONTEND_BASE

        ,apis: {
		user: {
			login: API_ROOT + '/AppUsers/login'
			,logout: API_ROOT + '/AppUsers/logout'
			,requestPasswordReset: API_ROOT + '/AppUsers/reset'
			,resetPassword: API_ROOT + '/AppUsers/reset-password'
			,verify: (userId) => `${API_ROOT}/AppUsers/${userId}/verify`
			,current: API_ROOT + '/AppUsers/me'
			,find: API_ROOT + '/AppUsers'
			,create: API_ROOT + '/AppUsers'
			,update: (userId) => `${API_ROOT}/AppUsers/${userId}`
			,findById: (userId) => `${API_ROOT}/AppUsers/${userId}`
			,delete: (userId) => `${API_ROOT}/AppUsers/${userId}`

			,grantedDashboards: API_ROOT + '/AppUsers/me/grantedDashboards'
			,favouriteDashboards: API_ROOT + '/AppUsers/me/favouriteDashboards'
			,addFavouriteDashboard: (id) => `${API_ROOT}/AppUsers/me/addFavouriteDashboard/${id}`
			,removeFavouriteDashboard: (id) => `${API_ROOT}/AppUsers/me/removeFavouriteDashboard/${id}`
			,grantedUploads: API_ROOT + '/AppUsers/me/grantedUploads'
		}
                ,clientTaxonomyItem: {
			loadData: (id) => `${API_ROOT}/ClientTaxonomyItems/${id}/loadData`
			,deleteData: (id) => `${API_ROOT}/ClientTaxonomyItems/${id}/deleteData`
			,queryData: (id) => `${API_ROOT}/ClientTaxonomyItems/${id}/queryData`
		}
	}

	,urls: {
		//http://localhost:3048/storage/clients/9001077493944072_mindshare-logo-png-7.png
		clientTaxonomyItemLogo: (path) => `${FRONTEND_BASE}/storage/${path}`
		
	}

	,EMBED_INFO_BY_TYPE: { //add 2 to the height
		'overview': { ratio: '151:176', label: 'Overview' }
		,'overview-new': { ratio: '171:517', label: 'Overview NEW' }
		,'overview-offline': { ratio: '151:300', label: 'Overview Offline' }
		,'display-video': { ratio: '151:422', label: 'Display&Video' }
		,'display-video-pop': { ratio: '171:537', label: 'Display&Video NEW' }
		/*,'display-video-overview': { ratio: '121:239', label: 'Display&Video' }
		,'display-video-awareness': { ratio: '121:410', label: 'Display&Video - Awareness' }
		,'display-video-consideration': { ratio: '121:292', label: 'Display&Video - Consideration' }
		,'display-video-conversion': { ratio: '121:339', label: 'Display&Video - Conversion' }*/
		,'ecommerce': { ratio: '151:416', label: 'Ecommerce' }
		,'ecommerce-new': { ratio: '171:506', label: 'Ecommerce NEW' }
		,'search': { ratio: '151:413', label: 'Search' }
		,'search-sa360-new': { ratio: '171:624', label: 'Search SA360 NEW' }
		,'search-gads-new': { ratio: '171:620', label: 'Search NEW' }
		,'search-seo': { ratio: '151:237', label: 'SEO' }
		,'search-seo-new': { ratio: '171:400', label: 'SEO NEW' }
		,'search-loreal': { ratio: '137:79', label: 'Search L\'Oreal' }
		,'social': { ratio: '151:421', label: 'Social' }
		,'social-new': { ratio: '171:537', label: 'Social NEW' }
		,'analytics': { ratio: '151:383', label: 'Analytics' }
		,'analytics-new': { ratio: '171:556', label: 'Analytics NEW' }
		,'cos-roas': { ratio: '151:285', label: 'COS & ROAS' }
		,'tv': { ratio: '151:265', label: 'Tv' }
		,'tv-new': { ratio: '171:619', label: 'Tv NEW' }
		,'drtv': { ratio: '151:293', label: 'DRTV' }
		,'radio': { ratio: '151:294', label: 'Radio' }
		,'radio-new': { ratio: '171:606', label: 'Radio NEW' }
		,'press': { ratio: '151:294', label: 'Press' }
		,'media-quality': { ratio: '151:395', label: 'Media Quality' }
		,'digital-funnel-lev': { ratio: '151:279', label: 'Digital Funnel LEV' }
		,'investments': { ratio: '151:532', label: 'Italian Mkt Adv. Expenditure' }
		,'investments-new': { ratio: '171:919', label: 'ITA Mkt Adv. Expenditure NEW' }
		,'investments-admin': { ratio: '151:532', label: 'IT Mkt Adv. Expend. Admin' }
		,'competition': { ratio: '151:359', label: 'Competition' }
		,'others': { ratio: '151:290', label: 'Other Channels' }
		,'others-new': { ratio: '171:527', label: 'Other Channels NEW' }
		,'naming-warnings': { ratio: '151:523', label: 'Warnings' }
		,'naming-warnings-new': { ratio: '171:858', label: 'Warnings NEW' }
		,'tutorial-new': { ratio: '171:374', label: 'Tutorial NEW' }
	}
}

export default conf;
